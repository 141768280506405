import '../styles/global.scss';

import { ChakraProvider } from '@chakra-ui/react';
import { appWithTranslation } from 'next-i18next';
import React from 'react';

const MyApp = ({ Component, pageProps }: any) => (
  <ChakraProvider>
    <Component {...pageProps} />
  </ChakraProvider>
);

export default appWithTranslation(MyApp);

// export default MyApp;
